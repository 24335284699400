<template lang="pug">
div
  el-dialog(:visible.sync="handoverModal" title="Kasa taslim listesi" center width="900px" top="20px")
    el-card(body-style="padding:0" v-if="can('cash.handover')")
      div(slot="header")
        el-form(inline label-position="left")
          el-form-item(label="İşlem tarihi")
            el-date-picker(
              v-model="handoverListFilters.date"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="{ firstDayOfWeek: 1 }"
              @change="showHandovers()"
              style="width: 150px"
              clearable
              placeholder="Tarih"
            )
          el-form-item(label="Para cinsi")
            el-select(
              placeholder="Para cinsi"
              v-model="handoverListFilters.currency"
              @change="showHandovers()"
              style="width: 150px"
              filterable
              clearable
            )
              el-option(
                v-for="c in $store.state.currencies"
                :key="c"
                :label="c"
                :value="c"
              )
          el-form-item(label="Ödeme aracı")
            el-select(
              placeholder="Ödeme aracı"
              v-model="handoverListFilters.type_id"
              @change="showHandovers()"
              style="width: 150px"
              filterable
              clearable
            )
              el-option(
                v-for="p in paymentTypes"
                :key="p.id"
                :label="p.name"
                :value="p.id"
              )
          el-form-item(label="")
            el-button(
              icon="el-icon-download"
              :disabled="true"
              @click="showHandovers({ excel: true })"
            ) Excel
      el-alert(
        v-if="handovers.length === 0"
        title="Kayıt yok"
        type="info"
        show-icon
        center
        :closable="false"
      )
      el-table(:data="handovers" size="small" v-if="handovers.length > 0")
        el-table-column(prop="branch.name" sortable label="Şube")
        el-table-column(prop="amount" sortable label="Tutar")
        el-table-column(prop="currency" sortable label="Para cinsi")
        el-table-column(prop="type.name" sortable label="Ödeme aracı")
        el-table-column(prop="submitted_user.name" sortable label="Kasiyer")
        el-table-column(prop="accepted_user.name" sortable label="Teslim alan")
        el-table-column(prop="created_at" sortable label="Teslim tarihi")
  el-alert(
    v-if="!can('cash.handover')"
    title="Yetkiniz yok"
    type="error"
    show-icon
    center
    :closable="false"
  )
  el-card(body-style="padding:0" v-if="can('cash.handover')")
    div(slot="header")
      el-form(inline)
        el-form-item(label="Şube")
          el-select(
            placeholder="Şube"
            v-model="filters.branch_id"
            @change="fetchTotals(); fetchCashierTotals();"
            filterable
          )
            el-option(
              v-for="b in $store.state.branches"
              :key="b.id"
              :label="b.name"
              :value="b.id"
            )
        el-form-item(label="Tarih")
          el-date-picker(
            v-model="filters.date"
            type="date"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
            :picker-options="{ firstDayOfWeek: 1 }"
            @change="fetchTotals"
            placeholder="Tarih"
          )
        el-form-item(label="")
          el-button(
            @click="recalc"
            icon="el-icon-refresh"
            :loading="recalcing"
          ) Yeniden hesapla
    el-alert(
      v-if="totals.length === 0 && !fetchingTotals"
      title="Seçtiğiniz tarihte satış bulunmamaktadır"
      type="error"
      show-icon
      center
      :closable="false"
    )

    el-divider(content-position="left" v-if="totals.length > 0")
      | KASA TOPLAMI
      small.ml-10 {{ filters.date|dayjs('D MMMM YYYY') }}

    grid.pb-5(
      v-if="totals.length > 0"
      clickable
      :column-num="totals.length"
      direction="horizontal"
      icon-size="18px"
      :gutter="5"
    )
      grid-item(
        v-for="(t, i) in totals"
        :key="i"
      )
        span.pl-10(slot="text")
          strong {{ t.currency }} &bullet;
          tag.ml-5(:type="t.amount > 0 ? 'success' : 'danger'") {{ t.amount }}
          br
          span.text-muted.text-success {{ t.type.name }}

    el-divider(content-position="left") TESLİM EDİLMEYEN TUTAR

    el-row(:gutter="0")
      el-alert(
        v-if="cashiers.length === 0"
        title="Teslim edilecek veri bulunamadı"
        type="info"
        show-icon
        center
        :closable="false"
      )
      el-col(
        :span="12"
        v-for="cashier in cashiers"
        :key="cashier.id"
      )
        el-card.m-10(body-style="padding: 0")
          div(slot="header")
            div.p-10
              strong Kasier: {{ cashier.name }}
              el-button.ml-20.micro(icon="el-icon-tickets" @click="showAllHandovers(cashier.id)") Tüm kayıtlar
          el-table(:data="cashier.cashier_totals" size="small")
            el-table-column(prop="debt" label="Teslim edilmeyen" align="right" width="150px" class-name="text-danger" sortable)
            el-table-column(prop="accepted" label="Teslim edilen" align="right" width="150px" class-name="text-success" sortable v-if="can('super-admin')")
            el-table-column(prop="currency" label="Para cinsi" align="center" sortable width="110px")
            el-table-column(prop="type.name" label="Ödeme aracı" align="center" sortable width="110px")
            el-table-column(prop="id")
              template(v-slot="props")
                el-popconfirm(
                  title="Teslim alma işlemini onaylıyor musunuz?"
                  confirm-button-text="Onay"
                  cancel-button-text="Vazgeç"
                  @confirm="handoverSingle(props.row)"
                )
                  el-button.micro(
                    slot="reference"
                    icon="el-icon-check"
                    :loading="saving === `${cashier.id}-${props.row.id}`"
                    :disabled="props.row.debt <= 0"
                  ) teslim al
                el-button.micro.ml-5(
                  icon="el-icon-tickets"
                  @click="showHandovers(props.row)"
                )
          center.mt-10
            el-popconfirm(
              title="Teslim alma işlemini onaylıyor musunuz?"
              confirm-button-text="Onay"
              cancel-button-text="Vazgeç"
              @confirm="handoverAll(cashier)"
            )
              el-button.mb-10(
                slot="reference"
                type="primary"
                size="small"
                icon="el-icon-finished"
                :loading="saving === cashier.id"
                :disabled="cashier.cashier_totals.filter(t => t.debt > 0).length === 0"
                plain
              ) Tümünü teslim al
</template>
<script>
import { Grid, GridItem, Tag } from 'vant'

export default {
  name: 'cash-handover',
  components: {
    Grid,
    GridItem,
    Tag
  },
  data() {
    return {
      cashiers: [],
      totals: [],
      handovers: [],
      paymentTypes: [],
      filters: {
        branch_id: this.$store.state.branch.id,
        date: window.dayjs().format('YYYY-MM-DD')
      },
      handoverListFilters: {
        branch_id: this.$store.state.branch.id,
        cashier_id: this.$store.state.branch.id,
        accepted_user_id: null,
        currency: null,
        type_id: null,
        date: window.dayjs().format('YYYY-MM-DD')
      },
      handoverModal: false,
      fetching: false,
      fetchingTotals: false,
      recalcing: false,
      saving: null,
    }
  },
  created() {
    this.branch_id = this.$store.state.branch.id
    this.fetchPaymentTypes()
    this.fetchTotals()
    this.fetchCashierTotals()
  },
  methods: {
    async fetchPaymentTypes() {
      let { data } = await window.axios.get('/payment-types')
      this.paymentTypes = data
    },
    async fetchCashierTotals() {
      const { data } = await window.axios.get(
        '/accounting/cashier-totals',
        { params: this.filters }
      )
      this.cashiers = data
    },
    async fetchTotals() {
      this.fetchingTotals = true
      const { data } = await window.axios.get(
        '/accounting/cash-daily-totals',
        { params: this.filters }
      )
      this.totals = data
      this.fetchingTotals = false
    },
    async handoverAll(cashier) {
      this.saving = cashier.id
      const ids = cashier.cashier_totals.filter(t => t.debt > 0).map(row => row.id)
      await window.axios.post('/accounting/cash-submit', { ids })
      this.saving = null
      this.fetchCashierTotals()
    },
    async handoverSingle(row) {
      this.saving = `${row.cashier_id}-${row.id}`
      await window.axios.post('/accounting/cash-submit', { ids: [row.id] })
      this.saving = null
      this.fetchCashierTotals()
    },
    async showHandovers(params) {
      this.handovers = []
      if (params) {
        this.handoverListFilters = Object.assign(this.handoverListFilters, params)
      }
      let { data } = await window.axios.get('/cash-handovers', { params: this.handoverListFilters })
      this.handovers = data
      this.handoverModal = true
    },
    async showAllHandovers(cashier_id) {
      this.handoverListFilters = { cashier_id }
      this.showHandovers()
    },
    async recalc() {
      this.recalcing = true
      await window.axios.post('accounting/cashier-totals-recalc')
      await this.fetchCashierTotals()
      await this.fetchTotals()
      this.recalcing = false
    }
  }
}
</script>